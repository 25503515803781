import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import Header from '../../Pages/Header';
import Sidebar from '../../Pages/Sidebar';
import moment from 'moment';

import { getUserDepartment } from '../../../../Store/User/Department/UserDepartment';
import { useDispatch, useSelector } from 'react-redux';
import { getUserShift } from '../../../../Store/User/Shift/getUserShift';
import CopyRight from '../../Pages/CopyRight';
import { MultiSelect } from 'react-multi-select-component';

const ManageDaily = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);

  const [selectValue, setSelectValue] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const formattedDate = `${year}-${month}-${day}`;
  const dayOfWeek = daysOfWeek[currentDate.getDay()];

  const [isPresent, setIsPresent] = useState(true);

  const [daily, setDaily] = useState({
    date: formattedDate,
    dayOfWeek: dayOfWeek,
    voucher_no: "",
    in_time: "",
    out_time: "",
    work_department: "",
    total_work_hour: 0,
    ot_hour: 0,
    half_day: false,
    present_day: "",
    late_hour: 0,
    early_going: 0,
    remark: "",
    shift: "",
    apchar: "",
    full_night_present: "",
    sunday_present: "",
    holiday_present: "",
  });

  const [employeeData, setEmployeeData] = useState([]);
  const [employAllData, setEmployAllData] = useState({
    designation: "",
    f_shift: "",
    f_in_time: "",
    f_out_time: "",
    f_shift_id: "",

    s_shift: "",
    s_shift_id: "",
    s_in_time: "",
    s_out_time: "",
    department: "",
    department_id: "",

    card_no: "",
    employee_id: "",
  });

  const data = location.state;

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
    getEmployee();
  }, [navigate]);

  useEffect(() => {
    const selectedDate = new Date(daily.date);
    const selectedDayOfWeek = daysOfWeek[selectedDate.getDay()];
    setDaily(prevState => ({ ...prevState, dayOfWeek: selectedDayOfWeek }));
    // eslint-disable-next-line
  }, [daily?.date]);

  useEffect(() => {
    if (location.state) {
      setDaily({
        date: moment(location.state?.date).format('YYYY-MM-DD'),
        voucher_no: location.state?.voucher_no,
        in_time: location.state?.in_time,
        out_time: location.state?.out_time,
        work_department: location.state?.work_department?._id,
        total_work_hour: location.state?.total_work_hour,
        ot_hour: location.state?.ot_hour,
        half_day: location.state?.half_day,
        present_day: location.state?.present_day,
        late_hour: location.state?.late_hour,
        early_going: location.state?.early_going,
        remark: location.state?.remark,
        apchar: location.state?.apchar,
        full_night_present: location.state?.full_night_present,
        sunday_present: location.state?.sunday_present,
        holiday_present: location.state?.holiday_present,
        shift: location.state?.shift?._id
      });

      setSelectedEmployee([{
        label: `${location.state.employee.full_name} - ${location.state.employee.employee_id} - 
        ${location.state.employee?.adhar_no}`, value: location.state.employee._id
      }]);
      setSelectValue(location.state.status);
    }
  }, [location.state]);


  const getEmployee = () => {
    const myurl = `${PAY_URL}/user/get-all-employee`;
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then(async (response) => {

      if (response?.data?.success) {
        const data = response?.data?.data
        const filterData = data.filter((e) => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID'));
        setEmployeeData(filterData);
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      toast.error("Something went wrong");
      console?.log("Errors", error);
    });
  }

  useEffect(() => {
    const selectEmployee = employeeData?.find(emp => emp?._id === selectedEmployee[0]?.value);
    setEmployAllData({
      designation: selectEmployee?.designation?.name,
      department: selectEmployee?.department?.name,
      department_id: selectEmployee?.department?._id,

      f_shift: selectEmployee?.shift?.name,
      s_shift: selectEmployee?.shift_two?.name,

      f_shift_id: selectEmployee?.shift?._id,
      s_shift_id: selectEmployee?.shift_two?._id,

      f_in_time: selectEmployee?.in_time,
      s_in_time: selectEmployee?.in_time_two,

      f_out_time: selectEmployee?.out_time,
      s_out_time: selectEmployee?.out_time_two,

      card_no: selectEmployee?.card_no,
      employee_id: selectEmployee?.employee_id
    });

  }, [selectedEmployee, employeeData]);


  const selectedEmpChange = (e) => {
    if (e.length > 1) {
      e = e.slice(-1);
    }
    setSelectedEmployee(e);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getUserDepartment()),
          dispatch(getUserShift())
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dispatch]);


  const departmentData = useSelector((state) => state?.getUserDepartment?.user?.data);
  const shiftData = useSelector((state) => state?.getUserShift?.user?.data);

  function calculatePresentDay(totalhours, expectedHours) {
    let presentDay = parseFloat((totalhours / expectedHours).toFixed(2));
    if (presentDay > 0.25 && presentDay < 0.5) {
      presentDay = 0.25;
    } else if (presentDay > 0.5 && presentDay < 0.75) {
      presentDay = 0.5;
    } else if (presentDay > 0.75 && presentDay < 1) {
      presentDay = 0.75;
    } else if (presentDay >= 1) {
      presentDay = 1;
    }
    return presentDay;
  }

  const calculateHours = (startTime, endTime) => {
    let hours = (endTime.getTime() - startTime.getTime()) / (1000 * 3600);
    if (hours < 0) {
      hours += 24;
    }
    return hours;
  };

  // const handleRadioChange = (event) => {
  //   setSelectValue(event.target.checked);
  // };

  // const handlePresentChange = (event) => {
  //   setIsPresent(event.target.value === 'present');
  //   if (event.target.value !== 'present') {
  //     setDaily(prevState => ({ ...prevState, present_day: 0 }));
  //   }
  // };

  const handleCheckBox = (e) => {
    // setDaily({ ...daily, half_day: e.target.checked });
    const presentDayValue = e.target.checked === true && 0.5;
    setDaily({ ...daily, half_day: e.target.checked, present_day: presentDayValue });
  }

  // working hour
  useEffect(() => {
    if (daily.in_time && daily.out_time) {
      try {
        const today = new Date();
        const inTime = new Date(`${today.toDateString()} ${daily.in_time}`);
        const outTime = new Date(`${today.toDateString()} ${daily.out_time}`)
        const expected_First_InTime = new Date(`${today.toDateString()} ${employAllData.f_in_time}`);
        const expected_First_OutTime = new Date(`${today.toDateString()} ${employAllData.f_out_time}`)
        const expected_Second_OutTime = new Date(`${today.toDateString()} ${employAllData.s_out_time}`)
        const expected_Second_InTime = new Date(`${today.toDateString()} ${employAllData.s_in_time}`);
        let lateHours = 0;
        let earlyGoingHrs = 0;
        const timeDiff = outTime.getTime() - inTime.getTime();

        const formatDecimal = (value) => {
          if (value > 0) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            return `${formattedHours}.${formattedMinutes}`;
          } else {
            return 0;
          }
        };

        let totalWorkHours = timeDiff / (1000 * 3600);
        totalWorkHours = calculateHours(inTime, outTime);
        if (totalWorkHours < 0) {
          totalWorkHours += 24;
        }

        let expectedHours = 0;

        if (daily.shift === employAllData.f_shift_id) {
          expectedHours = calculateHours(expected_First_InTime, expected_First_OutTime);
          lateHours = (inTime.getTime() - expected_First_InTime.getTime()) / (1000 * 60);
          earlyGoingHrs = (expected_First_OutTime.getTime() - outTime.getTime()) / (1000 * 60);
        } else {

          expectedHours = calculateHours(expected_Second_InTime, expected_Second_OutTime);
          lateHours = (inTime.getTime() - expected_Second_InTime.getTime()) / (1000 * 60);
          earlyGoingHrs = (expected_Second_OutTime.getTime() - outTime.getTime()) / (1000 * 60);

        }
        const presentDay = calculatePresentDay(totalWorkHours, expectedHours);

        setDaily(prevState => ({
          ...prevState,
          total_work_hour: (totalWorkHours.toFixed(2)),
          late_hour: formatDecimal(lateHours),
          early_going: formatDecimal(earlyGoingHrs),
          present_day: presentDay
        }));
      } catch (error) {
        console.error("Error calculating total work hour:", error);
      }
    }
  }, [daily.in_time, daily.out_time, daily.shift,
  employAllData.f_in_time, employAllData.f_out_time,
  employAllData.f_shift_id, employAllData.s_in_time, employAllData.s_out_time]);

  const handleChange = (e) => {
    setDaily({ ...daily, [e.target.name]: e.target.value });
  }

  const handleSubmit = async () => {
    if (validation()) {

      try {
        const form = new URLSearchParams();
        form.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
        form.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
        form.append('employee', selectedEmployee[0]?.value);
        form.append('date', daily?.date);

        const resSalary = await axios({
          method: 'post',
          url: `${PAY_URL}/user/employee-salary`,
          data: form,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
        })

        let attendanceType = '';
        if (daily?.full_night_present > 0) attendanceType += 'FN';
        if (daily?.sunday_present > 0) attendanceType += 'SP';
        if (daily?.holiday_present > 0) attendanceType += 'HP';

        if (resSalary.data.success) {
          setDisable(true)

          const bodyFormData = new URLSearchParams();
          bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
          bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

          bodyFormData.append('date', daily?.date);
          bodyFormData.append('employee', selectedEmployee[0].value);
          bodyFormData.append('voucher_no', daily?.voucher_no);
          bodyFormData.append('in_time', daily?.in_time);
          bodyFormData.append('out_time', daily?.out_time);

          bodyFormData.append('work_department', daily?.work_department);
          bodyFormData.append('total_work_hour', daily?.total_work_hour);
          bodyFormData.append('ot_hour', daily?.ot_hour);
          bodyFormData.append('half_day', daily?.half_day);
          bodyFormData.append('present_day', daily?.present_day === null ? 0 : daily?.present_day);
          bodyFormData.append('late_hour', daily?.late_hour === null ? 0 : daily?.late_hour);
          bodyFormData.append('early_going', daily?.early_going === null ? 0 : daily?.early_going);
          bodyFormData.append('remark', daily?.remark);

          bodyFormData.append('shift', daily.shift);

          bodyFormData.append('holiday_present', daily?.holiday_present);
          bodyFormData.append('sunday_present', daily?.sunday_present);
          bodyFormData.append('full_night_present', daily?.full_night_present);
          bodyFormData.append('apchar', daily?.apchar);
          bodyFormData.append('other_Apchar', attendanceType.trim());
          bodyFormData.append('sum_Apchar', daily.apchar + attendanceType);

          // bodyFormData.append('is_present', isPresent);
          if (data?._id) {
            bodyFormData.append('id', data?._id);
            bodyFormData?.append('status', selectValue);
          }

          axios({
            method: 'post',
            url: `${PAY_URL}/user/manage-daily-attendance`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
          }).then((response) => {
            // console.log(response?.data);
            if (response.data.success === true) {
              toast.success(response.data.message)
              navigate('/user/daily-attendance-management');
            } else {
              toast.error(response?.data?.message)
            }
            setDisable(false);
          }).catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message);
            setDisable(false);
          })
        } else {
          toast.error(resSalary.data.message)
        }
      } catch (error) {
        console.log(error, 'ERROR')
        toast.error(error?.response?.data?.message || 'Something went wrong');
      }
    }

  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  }

  const validation = () => {
    let isValid = true;
    let err = {};

    if (!daily?.date) {
      isValid = false;
      err['date_err'] = 'Please select date';
    }

    if (!daily?.work_department) {
      isValid = false;
      err['w_department_err'] = 'Please select department';
    }

    if (!selectedEmployee?.length > 0) {
      isValid = false;
      toast.error('Please select employee')
    }

    // if (!daily?.total_work_hour) {
    //   isValid = false;
    //   err['tw_hour_err'] = 'Please enter total working hours'
    // }

    if (daily?.holiday_present && daily.holiday_present < -1) {
      isValid = false;
      err['holiday_present_err'] = 'Please enter a value greater than 0';
    }

    if (daily?.sunday_present && daily.sunday_present < -1) {
      isValid = false;
      err['sunday_present_err'] = 'Please enter a value greater than 0';
    }

    if (daily?.full_night_present && daily.full_night_present < -1) {
      isValid = false;
      err['full_night_present_err'] = 'Please enter a value greater than 0';
    }


    if (isPresent === true) {
      if (!daily?.shift) {
        isValid = false;
        err['shift_err'] = 'Please select shift';
      }

      // if (!daily?.in_time) {
      //   isValid = false;
      //   err['in_time_err'] = 'Please enter in time';
      // }

      // if (!daily?.out_time) {
      //   isValid = false;
      //   err['out_time_err'] = 'Please enter out time';
      // }
    }

    setError(err);
    return isValid
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'}  Daily Attendance</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to='/user/daily-attendance-management'>Daily Attendance Management</Link></li>
                  <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Daily Attendance</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Daily Attendances</h4>
            </div>

            <div className="row card_id">

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date <span className='text-danger'> *</span></label>
                    <input type="date" onChange={handleChange} name='date' value={daily?.date} className="form-control" id="date"
                      disabled={data?._id ? 'disable' : ''}
                    />
                    <div className='error'>{error?.date_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">Day</label>
                    <input value={daily?.dayOfWeek} className="form-control" disabled />
                  </div>
                </div>
              </div>

              {data?._id ? (
                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="voucherNumber" className="form-label">Voucher No.</label>
                      <input type="number" className="form-control" id="voucherNumber"
                        name='voucher_no' value={daily?.voucher_no ? daily?.voucher_no : '-'} disabled />
                    </div>
                  </div>
                </div>
              ) : null}

            </div>

            <div className='row card_id'>
              <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="workDepartment" className="form-label">Employee - Id - Aadhar card <span className='text-danger'> *</span></label>
                    <MultiSelect
                      options={employeeData?.map(e => ({ label: `${e.full_name} - ${e?.employee_id} - ${e?.adhar_no}`, value: e._id }))}
                      onChange={selectedEmpChange}
                      // isLoading={true}
                      hasSelectAll={false}
                      value={selectedEmployee}
                      labelledBy="Select Employee"
                    />
                    <div className='error'>{error?.employee_err}</div>
                  </div>
                </div>
              </div>

            </div>

            {selectedEmployee?.length > 0 && (
              <>
                <div className='row card_id'>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Designation</label>
                        <input className="form-control" value={employAllData?.designation} disabled />
                      </div>
                    </div>
                  </div>

                </div>
                <div className='row card_id'>
                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Shift</label>
                        <input className="form-control" value={employAllData?.f_shift} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Expected In Time</label>
                        <input className="form-control" value={employAllData?.f_in_time} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Expected Out Time</label>
                        <input className="form-control" value={employAllData?.f_out_time} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Shift</label>
                        <input className="form-control" value={employAllData?.s_shift} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Expected In Time</label>
                        <input className="form-control" value={employAllData?.s_in_time} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Expected Out Time</label>
                        <input className="form-control" value={employAllData?.s_out_time} disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row card_id">

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="salaryDepartment" className="form-label">Work Department <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} value={daily?.work_department} name='work_department'
                    >
                      <option value="">Select Department</option>
                      {departmentData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error?.w_department_err}</div>
                  </div>
                </div>
              </div>

              {/* <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">Attendance</label>
                    <div className='d-flex gap-3 align-items-center'>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="present"
                          checked={isPresent}
                          onChange={handlePresentChange}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">Present</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="absent"
                          checked={!isPresent}
                          onChange={handlePresentChange}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">Absent</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="leaveHour" className="form-label">Attendance Character</label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} value={daily?.apchar} name='apchar'>
                      <option value="">Select Attendace</option>
                      <option value="A">Absent(A)</option>
                      <option value="P">Present(P)</option>
                      <option value="HD">Half Day(HD)</option>
                      <option value="H">Holiday(H)</option>
                      <option value="HP">Holiday Present(HP)</option>
                      <option value="O">Off(O)</option>
                      <option value="FN">Full Night(FN)</option>
                      <option value="SP">Sunday Present(SP)</option>
                      <option value="CL">Casual Leave(CL)</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>

            <div className='row card_id'>
              {!["A", "O", "H"].includes(daily?.apchar) && (
                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="salaryDepartment" className="form-label">Shift <span className='text-danger'> *</span></label>
                      <select className="form-select form-control" aria-label="Default select example"
                        onChange={handleChange} value={daily?.shift} name='shift'
                      >
                        <option value="">Select Shift</option>
                        {shiftData?.map((e) =>
                          <option value={e?._id} key={e?._id}>{e?.name}</option>
                        )}
                      </select>
                      <div className='error'>{error?.shift_err}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!["A", "O", "H"].includes(daily?.apchar) && (
              <>
                <div className='row card_id'>
                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="inTime" className="form-label">In  Time <span className='text-danger'> *</span></label>
                        <input type="time" className="form-control" id="inTime" name='in_time' value={daily?.in_time} onChange={handleChange} />
                        <div className='error'>{error?.in_time_err}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="outTime" className="form-label">Out Time <span className='text-danger'> *</span></label>
                        <input type="time" className="form-control" id="outTime" onChange={handleChange} value={daily?.out_time} name='out_time' />
                        <div className='error'>{error?.out_time_err}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="totalWorkHour" className="form-label">Total Work Hour <span className='text-danger'> *</span></label>
                        <input type="number" className="form-control" id="totalWorkHour"
                          value={daily?.total_work_hour}
                          disabled
                        />
                        <div className='error'>{error?.tw_hour_err}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="otHours" className="form-label">OT Hours</label>
                        <input type="number" className="form-control" id="otHours"
                          onChange={handleChange} name='ot_hour' value={daily?.ot_hour}
                          onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                        />
                        <div className='error'>{error?.ot_hour_err}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="halfDay" className="form-label">Half Day</label>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" role="switch" onChange={handleCheckBox} checked={daily?.half_day} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row card_id">
                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="lateHour" className="form-label">Late Hour</label>
                        <input type="number" className="form-control" id="lateHour"
                          name='late_hour' value={daily?.late_hour} disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="earlyGoing" className="form-label">Early Going</label>
                            <input type="number" className="form-control" id="earlyGoing"
                              name='early_going' value={daily?.early_going} disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="presentDay" className="form-label">Present Day</label>
                        <input type="number" className="form-control" id="presentDay"
                          name='present_day' value={daily?.present_day}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="earlyGoing" className="form-label">Sunday Present </label>
                            <input type="number" className="form-control" onChange={handleChange}
                              name='sunday_present' value={daily?.sunday_present}
                            />
                            <div className='error'>{error?.sunday_present_err}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="earlyGoing" className="form-label">Full Night Present</label>
                            <input type="number" className="form-control" onChange={handleChange}
                              name='full_night_present' value={daily?.full_night_present}
                            />
                            <div className='error'>{error?.full_night_present_err}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="earlyGoing" className="form-label">Holiday Present</label>
                            <input type="number" className="form-control" onChange={handleChange}
                              name='holiday_present' value={daily?.holiday_present}
                            />
                            <div className='error'>{error?.holiday_present_err}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* <div className='row card_id'>
              {!["A", "O", "H"].includes(daily?.apchar) && (
                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="presentDay" className="form-label">Present Day</label>
                      <input type="number" className="form-control" id="presentDay"
                        name='present_day' value={daily?.present_day}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="remark" className="form-label">Remark</label>
                    <textarea className="form-control" placeholder="Comment here...." id="remark"
                      onChange={handleChange} name='remark' value={daily?.remark}
                    ></textarea>
                  </div>
                </div>
              </div>

              {data?._id ? (
                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="fileUpload" className="form-label">Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div> */}

            <div className="row card_id mt-4 sm-mt-3">
              <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3 d-flex gap-2">
                    <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                    <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setDaily({
                      employee: "",
                      date: formattedDate,
                      voucher_no: "",
                      in_time: "",
                      out_time: "",
                      work_department: "",
                      total_work_hour: "",
                      ot_hour: "",
                      half_day: "",
                      present_day: "",
                      late_hour: "",
                      early_going: "",
                      remark: "",
                    })}> Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </div>
    </div>
  )
}

export default ManageDaily