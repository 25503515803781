import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../Pages/Header';
import Sidebar from '../../Pages/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Pagination } from '../../Table';
import Loader from '../../Pages/Loader';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { generateMonthName } from '../../Pages/MonthData';
import CopyRight from '../../Pages/CopyRight';
import TooltipWrapper from '../../Components/TooltipWrapper';
import { DownloadSample } from '../../Components/DownloadSample';
import ImportDailyAttendance from '../../Components/ImportDailyAttendance';
import DropDonwlLimit from '../../Components/DropDonwlLimit';
import ProcessLoader from '../../Components/ProcessLoader';


const DailyAllList = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [entity, setEntity] = useState([]);
    const [month, setMonth] = useState(0);
    const [datesForMonth, setDatesForMonth] = useState([]);
    const [limit, setlimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [disable, setDisable] = useState(true);
    const navigate = useNavigate();
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [openDaily, setOpenDaily] = useState(false);
    const [count, setCount] = useState({});

    const handleHideDailyModal = () => {
        setOpenDaily(false);
    }

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        setMonth(currentMonth.toString());
    }, []);

    useEffect(() => {
        const updateDatesForMonth = () => {
            const daysInMonth = new Date(new Date().getFullYear(), parseInt(month, 10), 0).getDate();
            const days = [];

            for (let i = 1; i <= daysInMonth; i++) {
                const currentDate = new Date(new Date().getFullYear(), parseInt(month, 10) - 1, i);
                const dayOfWeek = currentDate.getDay();
                const dayOfWeekText = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                days.push({
                    date: currentDate,
                    day: i,
                    dayOfWeek: dayOfWeekText[dayOfWeek]
                });
            }
            setDatesForMonth(days);
        };

        updateDatesForMonth();
        getDailyAttendance();
        // eslint-disable-next-line
    }, [month, disable, search, limit]);

    const commentsData = useMemo(() => {
        let computedComments = entity;
        return computedComments
    }, [entity]);

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setMonth(selectedMonth);
    };

    const getDailyAttendance = () => {
        const myurl = `${PAY_URL}/user/get-admin-daily-attendance?page=${currentPage}&limit=${limit}
        &firm=${localStorage.getItem('PAY_USER_FIRM_ID')}&year=${localStorage.getItem('PAY_USER_YEAR_ID')}&search=${search}&month=${month}`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            // console?.log("@@", response?.data);
            if (response?.data?.success) {
                const data = response?.data?.data;
                setEntity(data?.data);
                setTotalItems(data.totalData);
                setCurrentPage(data.currentPage);
                setCount(data?.counts);
                setDisable(false);
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        });
    };

    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleRefresh = () => {
        setDisable(true);
        setSelectedEmployee([]);
        setSearch('');
        const currentMonth = new Date().getMonth() + 1;
        setMonth(currentMonth.toString());
        setlimit(10);
    }

    const handleCheckEmployee = (e, id) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedEmployee(prevSelected => [...prevSelected, { id }]);
        } else {
            setSelectedEmployee(prevSelected => prevSelected.filter(emp => emp.id !== id));
        }
    }

    const handleCheckAllEmpployee = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        if (isChecked) {
            const allEmployeeIds = entity.map(item => item.employee._id);
            const uniqueIds = Array.from(new Set(allEmployeeIds));
            setSelectedEmployee(uniqueIds.map(id => ({ id })));
        } else {
            setSelectedEmployee([]);
        }
    }

    const handleEdit = (date, id) => {
        const employeeData = entity.find(i => moment(i.date).format('YYYY-MM-DD') === moment(date.date).format('YYYY-MM-DD') && i.employee?._id === id);
        navigate('/user/manage-daily-attendance', { state: employeeData })
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setDisable(true);
    };

    const handleLimitChange = (value) => {
        setlimit(value);
        setCurrentPage(1);
        setDisable(true);
    };
    const handleSearchChange = (value) => {
        setSearch(value);
        setCurrentPage(1);
        setDisable(true);
    };

    const generateReport = () => {
        const myurl = `${PAY_URL}/user/daily-attendance-report`;
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
        bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
        bodyFormData.append('month', month);
        bodyFormData.append('employee', JSON.stringify(selectedEmployee));
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((response) => {
            if (response.data.success === true) {
                console.log(response.data, '@@@')
                toast.success(response.data.message);
                const data = response.data.data
                const formattedReportData = formatForXLSX(data);
                const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet('Attendance Report');

                worksheet.mergeCells('L2:U2');
                worksheet.getCell('L2').value = `Attendance data of ${generateMonthName(parseInt(month))} `;
                worksheet.getCell('L2').font = { bold: true };

                worksheet.getCell('L2').alignment = { vertical: 'middle', horizontal: 'center' };

                worksheet.getRow(5).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                };

                const headerRow = worksheet.addRow(['Sr.', 'Employee', ...datesForMonth.map(day => day.day)]);

                headerRow.eachCell((cell, index) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFADD8E6' }
                    };
                });

                const srNoColumn = worksheet.getColumn(1);
                srNoColumn.width = 5;

                const employeeColumn = worksheet.getColumn(2);
                employeeColumn.alignment = { vertical: "top", horizontal: "left" };
                employeeColumn.width = 65;

                for (let i = 3; i <= datesForMonth.length + 2; i++) {
                    const column = worksheet.getColumn(i);
                    column.width = 6;
                }

                formattedReportData.forEach((row, rowIndex) => {
                    const newRow = worksheet.addRow([rowIndex + 1, ...row]);
                    newRow.eachCell((cell, index) => {
                        if (index > 2) {
                            const status = row[index - 2];
                            let fontColor = '000000';
                            switch (status) {
                                case 'A':
                                    fontColor = 'FF0000';
                                    break;
                                case 'P':
                                    fontColor = '006400';
                                    break;
                                case 'HD':
                                    fontColor = '4B0082';
                                    break;
                                default:
                                    fontColor = '000000';
                                    break;
                            }
                            cell.font = {
                                color: { argb: fontColor }
                            };
                        }
                    });
                });

                worksheet.eachRow(row => {
                    row.eachCell((cell, colNumber) => {
                        if (colNumber !== 2) {
                            cell.alignment = { vertical: 'middle', horizontal: 'center' };
                        }

                    });
                });


                workbook.xlsx.writeBuffer().then(buffer => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'daily_attendance_report.xlsx');
                });
            }
        }).catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message);
            setDisable(false);
        })
    }

    const handleDownloadFile = () => {
        if (month) {
            const startYear = localStorage.getItem('PAY_USER_START_YEAR').substring(0, 4);
            const endYear = localStorage.getItem('PAY_USER_END_YEAR').substring(0, 4);
            const monthYear = month >= 4 && month <= 12 ? startYear : endYear;
            const date = `${monthYear}-${('0' + month).slice(-2)}-01`;

            const bodyFormData = new URLSearchParams();
            bodyFormData.append("date", date);
            DownloadSample({ url: 'download-daily-attendance-sheet', bodyFormData: bodyFormData, filename: 'daily-attendace' });
        } else {
            toast.error("Please select a month to download!");
        }
    }
    const [attCard, serAttCard] = useState(false);
    const [filter, setFilter] = useState({
        date: {
            start: null,
            end: null
        }
    });
    const handleDownloadAttendance = () => {
        if (month) {
            serAttCard(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append("firm_id", localStorage.getItem('PAY_USER_FIRM_ID'));
            bodyFormData.append("year_id", localStorage.getItem('PAY_USER_YEAR_ID'));
            bodyFormData.append("month", month);
            bodyFormData.append("print_date", true);
            bodyFormData.append('filter', filter);
            bodyFormData.append('search', search);
            axios({
                method: "post",
                url: `${PAY_URL}/user/download-attendance-ledger`,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') },
            }).then((response) => {
                if (response.data.success === true) {
                    const fileUrl = response.data.data.file;
                    window.open(fileUrl, '_blank');
                    toast.success(response.data.message);
                } else {
                    toast.error(response?.data.message);
                }
                serAttCard(false)
            }).catch((error) => {
                toast.error(error?.response?.data?.message);
                serAttCard(false)
            })
        } else {
            toast.error("Please select a month to download!");
        }
    }

    function formatForXLSX(data) {
        const formattedData = [];
        for (const employee of data) {
            const employeeRow = [employee.name];
            const attendanceMap = new Map(employee.attendance.map(entry => [moment(entry.date).format('YYYY-MM-DD'), entry]));
            datesForMonth.forEach(day => {
                const attendance = attendanceMap.get(moment(day.date).format('YYYY-MM-DD'));
                if (attendance) {
                    employeeRow.push(attendance.status);
                } else {
                    employeeRow.push('-');
                }
            });
            formattedData.push(employeeRow);
        }
        return formattedData;
    }

    return (
        <div className={`main - wrapper ${isSidebarOpen ? 'slide-nav' : ''} `}>
            <Header handleOpen={handleOpen} />
            <Sidebar />
            <div className="page-wrapper user-account">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">Daily Attendance Management</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Daily Attendance Management</li>
                                </ul>
                            </div>
                            <div className="col-auto float-end ms-auto">
                                <button type='button' onClick={() => navigate('/user/manage-daily-attendance')} className="btn btn-success"><i className="fa-solid fa-plus"></i> Add Daily Attendance</button>
                            </div>
                        </div>
                    </div>

                    <div className="row filter-row justify-content-end">
                        <div className='col-md-4 col-sm-12'>
                            <div className="row justify-content-start">
                                <div className="col-md-12 mb-2">
                                    <select className="form-select form-control" value={month} onChange={handleMonthChange}
                                        aria-label="Default select example">
                                        <option value=''>Select Month</option>
                                        <option value='1'>January</option>
                                        <option value='2'>February</option>
                                        <option value='3'>March</option>
                                        <option value='4'>April</option>
                                        <option value='5'>May</option>
                                        <option value='6'>June</option>
                                        <option value='7'>July</option>
                                        <option value='8'>August</option>
                                        <option value='9'>September</option>
                                        <option value='10'>October</option>
                                        <option value='11'>November</option>
                                        <option value='12'>December</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-8">
                            <div className="row justify-content-end">
                                <div className="col-md-12 mb-2">
                                    <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                                        <div className="seletePage d-flex gap-2 align-items-center ">
                                            <p className="mb-0">Rows per page:</p>
                                            <DropDonwlLimit limit={limit} onLimitChange={handleLimitChange} />
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <TooltipWrapper tooltipText="Import File">
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={() => setOpenDaily(true)}>
                                                            <i className="fa-solid fa-upload"></i>
                                                        </button>
                                                    </div>
                                                </TooltipWrapper>
                                                <TooltipWrapper tooltipText="Download Sample File">
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={handleDownloadFile}>
                                                            <i className="fa-solid fa-file"></i>
                                                        </button>
                                                    </div>
                                                </TooltipWrapper>
                                                <TooltipWrapper tooltipText="Download Attendance Card">
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={handleDownloadAttendance}>
                                                            {attCard ? <ProcessLoader /> : <i className="fa-solid fa-download" />}
                                                        </button>
                                                    </div>
                                                </TooltipWrapper>
                                                <TooltipWrapper tooltipText="Refresh">
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={handleRefresh}>
                                                            <i className="fa-solid fa-arrows-rotate"></i>
                                                        </button>
                                                    </div>
                                                </TooltipWrapper>
                                                {selectedEmployee.length > 0 ? (
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={generateReport}>
                                                            <i className="fa-solid fa-download"></i>
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="tableSearch">
                                            <form action="">
                                                <input
                                                    type="text"
                                                    placeholder="Search here"
                                                    onChange={(e) => handleSearchChange(e.target.value)}
                                                    className="form-control"
                                                />
                                                <span className="search">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className='d-flex justify-content-start align-items-center gap-1'>
                                                            <input className="form-check-input mt-0" type="checkbox" id="flexCheckDefault"
                                                                style={{ width: "15px", height: "15px" }}
                                                                onChange={handleCheckAllEmpployee}
                                                                checked={selectAll}
                                                            />
                                                            Employee
                                                        </div>
                                                    </th>
                                                    <th>
                                                        Card No.
                                                    </th>
                                                    {datesForMonth.map(day => (
                                                        <th key={day.date.toString()} style={{ textAlign: "center", color: day.dayOfWeek === 'Saturday' || day.dayOfWeek === 'Sunday' ? 'red' : 'black' }}>
                                                            {day.day}
                                                        </th>
                                                    ))}
                                                    <th className='text-end'>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {commentsData.reduce((acc, curr) => {
                                                    const existingEmployeeIndex = acc.findIndex(item => item.employee._id === curr.employee._id);
                                                    if (existingEmployeeIndex === -1) {
                                                        acc.push({
                                                            employee: curr.employee,
                                                            attendanceData: [{ date: curr.date, apchar: curr.apchar, sum_Apchar: curr.sum_Apchar }]
                                                        });
                                                    } else {
                                                        acc[existingEmployeeIndex].attendanceData.push({ date: curr.date, apchar: curr.apchar, sum_Apchar: curr.sum_Apchar });
                                                    }
                                                    return acc;
                                                }, []).map((item, index) => {

                                                    const totalPCount = item.attendanceData.filter(attendance => {
                                                        const attendanceDate = moment(attendance.date);
                                                        return attendance.apchar === 'P' && attendanceDate.month() + 1 === parseInt(month);
                                                    }).length;

                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex gap-1 justify-content-start align-items-center">
                                                                    <input className="form-check-input mt-0" type='checkbox'
                                                                        style={{ width: "15px", height: "15px" }}
                                                                        onChange={(e) => handleCheckEmployee(e, item.employee._id)}
                                                                        checked={selectedEmployee.some(emp => emp.id === item?.employee?._id)}
                                                                    />
                                                                    {item.employee?.full_name}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item?.employee?.card_no}
                                                            </td>
                                                            {datesForMonth.map((day, dayIndex) => {
                                                                const attendance = item?.attendanceData?.find(data => moment(data.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'));
                                                                console.log()
                                                                let attendanceSymbol = '-';
                                                                let badgeClass = '';

                                                                if (attendance) {
                                                                    if (attendance.sum_Apchar === 'P') {
                                                                        attendanceSymbol = 'P';
                                                                        badgeClass = 'badge-success';
                                                                    } else if (attendance.sum_Apchar === 'HD') {
                                                                        attendanceSymbol = 'HD';
                                                                        badgeClass = 'badge-purple';
                                                                    } else if (attendance.sum_Apchar === 'A') {
                                                                        attendanceSymbol = 'A';
                                                                        badgeClass = 'badge-danger';
                                                                    } else {
                                                                        attendanceSymbol = attendance.sum_Apchar;
                                                                        badgeClass = 'badge-secondary';
                                                                    }
                                                                }

                                                                return (
                                                                    <td key={dayIndex} style={{ textAlign: "center", color: day.dayOfWeek === 'Saturday' || day.dayOfWeek === 'Sunday' ? 'red' : 'black' }}>
                                                                        {attendanceSymbol !== '-' ? (
                                                                            <span className={`badge ${badgeClass} `} style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => handleEdit(attendance, item?.employee?._id)}>
                                                                                {attendanceSymbol}
                                                                            </span>
                                                                        ) : (
                                                                            attendanceSymbol
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                            <td className='text-end'><b>{totalPCount}</b></td>
                                                        </tr>
                                                    )
                                                })}

                                                {commentsData?.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="999">
                                                            <div className="no-table-data">
                                                                No Data Found!
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-center mt-3 user-account">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                            <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                            <div className="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={limit}
                                                    currentPage={currentPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}

                    <div className='card p-3'>
                        <h5><b>Note:</b> If you need to make any edits or updates to your attendance, simply click on the <b>date</b> corresponding to the attendance you wish to edit.</h5>
                        <ul style={{ fontSize: "12px" }}>
                            <li className='mt-1 d-flex'><div style={{ backgroundColor: "#55ce63", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div>Present: ({count?.P})</li>
                            <li className='mt-1 d-flex'><div style={{ backgroundColor: "#ff3131", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div>Absent: ({count?.A})</li>
                            <li className='mt-1 d-flex'><div style={{ backgroundColor: "#9368e9", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div>Half Day: ({count?.HD})</li>
                            <li className='mt-1 d-flex'><div style={{ backgroundColor: "#9368e9", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div>Sunday Present: ({count?.SP})</li>
                        </ul>
                    </div>
                </div>
                <CopyRight />
            </div>
            <ImportDailyAttendance
                openDaily={openDaily}
                handleHideDailyModal={handleHideDailyModal}
                title={'Daily Attendance Import'}
                url={'/import-daily-attendance'}
                getDailyAttendance={getDailyAttendance}
            />
        </div>
    );
};

export default DailyAllList;
